import React from "react"
import Helmet from "react-helmet"
import SEO from "../../components/seo"

function FormPage () {

return (
<>
<SEO title="The Listening Benchmark"/> 
<div className="pt-6">
    <img className="mx-auto" width="100px" alt="" src="https://res.cloudinary.com/mysnapshot/image/upload/v1736081932/Course%20Logos/Master_Logo_Black_ugrloq.png"/>
</div>
<div className="-mt-8" id="ff-compose"></div>
<Helmet>
    <style>{`
        #Publish1FAIpQLScGFt98Cx0u2z_wNrqiresfAF_obJTuAwIlzxL_ZWNbTLGRyQ {
            padding: 30px !important;
            padding-bottom: 100px !important;
 }

        .ff-compose {
            min-height: calc(100vh - 30px) !important;
        }

/* Existing code. */
#___gatsby {
    background-color: #FFFFFF !important;
}

/* FIX: Horizontal scroll for Linear scale */
@media (max-width: 700px) {
    .ff-linear-scale-button {
        min-width: 25px !important;
        height: 33px !important;
    }

    .ff-linear-scale-button {
        border-width: 1.5px !important;
        border-left-width: 0px !important;
    }
}

.ff-linear-scale-input-wrapper button {
    font-size: 13.75px !important;
    font-weight: 500 !important;
    letter-spacing: 0px !important;
}

.ff-linear-scale-label-text {
    width: 140px !important;
}

@media (max-width: 400px) {
    .ff-linear-scale-label-text {
        width: 120px !important;
    }
}
/* END FIX: Horizontal scroll for Linear scale */

/* Removed Exclamation Mark in Warning Message. */
.ff-widget-error b {
    display: none !important;
}
/* END Removed Exclamation Mark in Warning Message. */

/* Warning message text style. */
div.ff-widget-error {
    color: #B52632 !important;
    font-weight: 400 !important;
}
/* END Warning message text style. */

/* Submit page style */
.ff-button-bar button {
    height: 44px !important;
}
.rest-btn.ff-back {
    background: transparent !important;
    border: 1px solid #e1d7d7 !important;
}
/* END Submit page style */

/*  Style for You partially filled this form */
.ff-partial.ff-message {
    background: #eeeeee !important;
    color: black !important;
}
.ff-partial.ff-message a {
    color: black !important;
}
/* END Style for You partially filled this form */

/* Black asterisk  */
span.ff-required {
    color: black !important;
}
/* END Black asterisk */

/* Tutor image remove padding */ 
.ff-layout-1column .ff-secfields {
    padding-bottom: 0 !important;
    }
/* END Tutor image remove padding */ 

/* Main video remove padding */
.ff-video { 
    padding: 0 !important;
}
/* END Main video remove padding */

/* Button styling updates */ 
.ff-form button { 
    text-transform: none !important;
    letter-spacing: 0;
    border: 1px solid #D3D3D3 !important; /* Button and linear scale borders */ 
}
.ff-form button:hover { 
    --tw-shadow: 0 !important;
    --tw-shadow-colored: 0 !important;
    box-shadow: 0 !important;
}
.ff-form button.ff-back:hover {
    background-color: #f1f1f1 !important;
}
.ff-form button.ff-next, .ff-form button.ff-submit {
    background-color: #0da299 !important;
}
.ff-form button.ff-next:hover, .ff-form button.ff-submit:hover {
    background-color: #008880 !important;
}
.ff-scale button.ff-linear-scale-button-active, .ff-scale button.ff-linear-scale-button-partial-active {
    background-color: #0DA299 !important; /* Linear scale selected color */
    color: white !important;
}
.ff-linear-scale-button:hover {
    background-color: rgba(13, 162, 153, 0.25) !important; /* Linear scale hover color */
}
/* END Button styling updates */ 

/* padding for warning message box */
@media (max-width: 400px) {
    .ff-message {
        margin-left: 0px !important;
        margin-right: 0px !important;
        font-size: 14px !important; 
    }
}
/* END padding for warning message box */

/* Responsive extra styling */
@media (max-width: 400px) {
    .ff-layout-2column, .ff-layout-1column, .ff-layout-default { 
    padding: 1.5em !important;
    }
    .ff-message {
        margin-left: 1.5em !important;
        margin-right: 1.5em !important;
    }
}
@media (min-width: 768px) {
    .ff-message {
        margin-bottom: 0 !important;
    }
}
/* END Responsive extra styling */

/* Padding and alignment extra styling */
.ff-layout-1column .ff-form-check-input, .ff-layout-2column .ff-form-check-input {
    accent-color: #00746e !important; /* Checkbox and radio buttons input accent color */
}
.ff-layout-1column .ff-form-check:has(input:checked), .ff-layout-2column .ff-form-check:has(input:checked) {
    outline: 1px solid #0DA299 !important; /* Outline for checkbox and radio buttons */
}
.ff-form .ff-title { 
    margin-bottom: 0 !important; /* Padding below page titles */
}
.rest-form-group {
    padding-bottom: 0.5em; /* Padding between form groups */
}
.pt-6 { padding: 2em !important; } /* Logo section padding */
.ff-submit-icon { margin-bottom: 0 !important; } /* Correct Submit button icon position */
.ff-form .ff-title { margin-bottom: 0 !important; } /* Remove margin below hidden Form Title */
.ff-description img { margin-bottom: 0 !important; } /* Remove margin below image in FF */ 
.ff-button-bar { margin-top: 2em !important; } /* Spacing above the button bar */ 
.ff-form textarea { margin-top: 1em !important; } /* Spacing above the text inputs */
.ff-linear-scale-container { margin-top: .3em !important; } /* Spacing above the linear scale */
.ff-form input[type="text"], .ff-form input[type="email"] { margin-top: .75em !important; } /* Spacing above other input fields */
.ff-layout-1column .ff-form-check, .ff-layout-2column .ff-form-check { margin-top: .5em !important; } /* Space above checkboxes */
/* END Padding and alignment extra styling */

/* Font and color tweaks */
.ff-section-header { font-weight: 600 !important; } /* Add Title */
.ff-help { font-size: 1em !important; } /* Size of question subtext */
.ff-layout-1column .ff-form-check:has(input:checked), .ff-layout-2column .ff-form-check:has(input:checked) { 
    background: rgba(13, 162, 153, 0.25) !important; /* Tinted background on checkboxes */
    }
:root {
--ff-primary-700: #000000;
}
/* END Font tweaks */

        `}
    </style>
        <script async defer src="https://formfacade.com/include/105416567367288830990/form/1FAIpQLScGFt98Cx0u2z_wNrqiresfAF_obJTuAwIlzxL_ZWNbTLGRyQ/classic.js?div=ff-compose">
        </script></Helmet>


</>
)
} 

export default FormPage
